import React from "react";

// css
import "./About-us.css";

// assets
import dcreImg from "../../assets/images/HISTORY-ICON1.png";
import wiImg from "../../assets/images/HISTORY-ICON2.png";
import hcreImg from "../../assets/images/HISTORY-ICON3.png";
import creImg from "../../assets/images/HISTORY-ICON4.png";
import siiImg from "../../assets/images/HISTORY-ICON5.png";
import reiImg from "../../assets/images/HISTORY-ICON6.png";
import usccImg from "../../assets/images/HISTORY-ICON7.png";
import idtfImg from "../../assets/images/HISTORY-ICON8.png";

const Overview = () => {
  return (
    <section className="overview" id="overview">
      <div class="main-padding overview-container">
        <div className="main-margin main-header-margin">
          <h1 className="main-header">History</h1>
          <p className="t-left">
            With its origin dating back to 2009, StratCap was founded with the
            objective of sourcing and capitalizing exceptional investment teams
            to acquire, manage and operate various alternative assets on behalf
            of both retail and institutional partners.
          </p>
        </div>
      </div>
      <div className="main-padding overview-bottom-container">
        <div className="main-margin overview-content-wrapper">
          <p>
            Since our founding, we have raised over $5 billion in equity capital
            and participated in approximately $8 billion in capital deployed
            across a variety of sectors, including:
          </p>
          <div class="overview-grid">
            <div class="overview-grid-card">
              <img src={dcreImg} alt="img" />
              <p>
                Data center <br />
                real estate
              </p>
            </div>
            <div class="overview-grid-card">
              <img src={wiImg} alt="img" />
              <p>Wireless Infrastructure</p>
            </div>
            <div class="overview-grid-card">
              <img src={hcreImg} alt="img" />
              <p>
                Healthcare <br /> real estate
              </p>
            </div>
            <div class="overview-grid-card">
              <img src={creImg} alt="img" />
              <p>Core commercial real estate</p>
            </div>
            <div class="overview-grid-card">
              <img src={siiImg} alt="img" />
              <p>Social impact investing</p>
            </div>
            <div class="overview-grid-card">
              <img src={reiImg} alt="img" />
              <p>Renewable energy infrastructure</p>
            </div>
            <div class="overview-grid-card">
              <img src={usccImg} alt="img" />
              <p>U.S. Corporate credit</p>
            </div>
            <div class="overview-grid-card">
              <img src={idtfImg} alt="img" />
              <p>International debt and trade finance</p>
            </div>
          </div>
          <p>
            Through a group of affiliated organizations, StratCap offers
            institutional asset management services, product solutions for
            financial professionals and their clients, and operations and
            administration services – all supported by a sophisticated
            proprietary technology platform.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Overview;
