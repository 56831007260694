import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// css
import "./WhatWeDo.css";

// assets
import img1 from "../../assets/images/STRATCAPSECURITIES-ICON1.png";
import img2 from "../../assets/images/STRATCAPSECURITIES-ICON2.png";
import img3 from "../../assets/images/STRATCAPSECURITIES-ICON3.png";
import img4 from "../../assets/images/STRATCAPSECURITIES-ICON4.png";
import img5 from "../../assets/images/STRATCAPSECURITIES-ICON5.png";
import img6 from "../../assets/images/STRATCAPSECURITIES-ICON6.png";
import posterImg from "../../assets/images/SCS.jpg";
import headerVideo from "../../assets/videos/SCS.mp4";
import mobileHeaderImg from "../../assets/images/SCS-MOBILE.jpg";

const WholesaleDistribution = () => {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return (
    <>
      <section className="wwd-overview" id="wholesale-distribution">
        {/* <div class="main-padding wwd-overview-container">
          <div className="main-header-margin">
            <h1 className="main-header">StratCap Securities</h1>
          </div>
        </div> */}
        {/* TEST START */}
        <div className="video-header-container">
          <div className="video-header-content-container">
            {screenSize.dynamicWidth < 665 ? (
              <div>
                <img
                  src={mobileHeaderImg}
                  alt="stratcap advisory services header"
                  className="mobile-header-img"
                />
                <div className="header-video-text-overlay main-margin">
                  <div className="main-header-margin">
                    <h1 className="header-video-overlay-text">
                      StratCap Securities
                    </h1>
                  </div>
                </div>
              </div>
            ) : (
              <div className="wd-header">
                {/* <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  poster={posterImg}
                  id="header-video"
                >
                  <source src={headerVideo} type="video/mp4" />
                  Your browser does not support HTML5 video.
                </video> */}
                <div className="header-video-text-overlay main-margin">
                  <div className="main-header-margin">
                    <h1 className="header-video-overlay-text">
                      StratCap Securities
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* TEST END */}
        <div className="main-padding wwd-solutions-container">
          <div className="main-margin negative-margin">
            <p className="t-left wholesale-distribution-card">
              StratCap Securities is a managing broker dealer whose primary
              mission is to raise capital for investment programs tailored to
              retail investors. The company does this through a proven wholesale
              distribution model whose clientele includes broker dealers,
              registered investment advisors, family offices and the like.
            </p>
          </div>
          <div className="main-margin wwd-solutions-main-wrapper">
            <div className="wwd-wholesale-content-wrapper">
              <h1>A Robust Distribution Organization</h1>
              <p>
                The goal of StratCap Securities is to provide financial advisors
                and their clients access to innovative investment structures,
                asset classes and strategies.
              </p>
              <p>
                Since its inception, StratCap Securities has helped develop
                various investment programs and established itself as a leader
                in the retail alternative investment arena.
              </p>
              {/* links to institutional team on contacts page, or opens general email to someone in the institutional team */}
              <div className="dist-learn-button-wrapper">
                <Link to="/contact-us" className="link-button">
                  Contact Us to Learn More
                </Link>
              </div>
            </div>
            <div className="wholesale-dist-top-grid">
              <div>
                {screenSize.dynamicWidth < 1025 ? (
                  <h1 className=" wholesale-dist-card-header">$5B+</h1>
                ) : (
                  <h1 className=" wholesale-dist-card-header">
                    $5 Billion+
                  </h1>
                )}
                <p className="wholesale-dist-card-info">
                  Investor capital raised
                </p>
              </div>
              <div>
                <h1 className=" wholesale-dist-card-header">
                  100,000+
                </h1>
                <p className="wholesale-dist-card-info">Investors</p>
              </div>
              <div>
                <h1 className=" wholesale-dist-card-header">3000+</h1>
                <p className="wholesale-dist-card-info">RIA relationships</p>
              </div>
              <div>
                <h1 className=" wholesale-dist-card-header">400+</h1>
                <p className="wholesale-dist-card-info">BD relationships</p>
              </div>
            </div>
          </div>
          <div className="main-margin">
            <div className="wholesale-dist-bottom-grid">
              <div>
                <img src={img1} alt="" />
                <p>
                  Wholesale <br /> Distribution
                </p>
              </div>
              <div>
                <img src={img2} alt="" />
                <p>
                  Compliance <br /> & Legal
                </p>
              </div>
              <div>
                <img src={img3} alt="" />
                <p>
                  Sales, Service <br /> & Support
                </p>
              </div>
              <div>
                <img src={img4} alt="" />
                <p>Due Diligence</p>
              </div>
              <div>
                <img src={img5} alt="" />
                <p>Operations</p>
              </div>
              <div>
                <img src={img6} alt="" />
                <p>
                  Marketing <br /> & Research
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WholesaleDistribution;
