import React from "react";

// css
import "./About-us.css";

const Numbers = () => {
  return (
    <section class="numbers" id="bythenumbers">
      <div class="main-padding numbers-container">
        <div class="main-margin numbers-main-wrapper">
          <div className="main-header-margin numbers-header">
            <h1 className="main-header">By The Numbers</h1>
            <p>
              Throughout our history we have provided a variety of investment
              strategies and solutions for institutional and retail investors.
            </p>
          </div>
        </div>
      </div>
      <div class="main-padding numbers-bottom-container">
        <div class="main-margin numbers-content-wrapper">
          <div class="card-grid">
            <div class="grid-card">
              <div className="numbers-card" id="numbers-card-mobile">
                <div>
                  <h1>$35​+ billion</h1>
                  <p>
                    approximate combined acquisitions, dispositions,
                    development, financings and asset management experience of
                    our team​
                  </p>
                </div>
                <hr />
                <div>
                  <h1>116,000​</h1>
                  <p>approximate # of investors​</p>
                </div>
              </div>
            </div>
            <div class="grid-card">
              <div className="numbers-card">
                <div>
                  <h1>$8 billion</h1>
                  <p>
                    approximate capital deployed through our services platform
                  </p>
                </div>
                <hr />
                <div>
                  <h1>500+​</h1>
                  <p>assets and investment positions​</p>
                </div>
              </div>
            </div>
            <div class="grid-card">
              <div className="numbers-card">
                <div>
                  <h1>$5 billion</h1>
                  <p>approximate investor capital raised​</p>
                </div>
                <hr />
                <div>
                  <h1>300+​</h1>
                  <p>
                    cities where teams have completed acquisitions or
                    dispositions​
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
