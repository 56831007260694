import React, { useState } from "react";

// CSS
import "./MainPage.css";

// Assets
import heroVideo from "../../assets/videos/StratCap Homepage Video.mp4";
import whiteLogo from "../../assets/images/logo1-default.png";
import hmcCapLogo from "../../assets/images/HMC-Capital-logo.svg";

const MainPage = (props) => {
  const [showModal, setShowModal] = useState(true);

  // const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  // const [transform, setTransform] = useState(false);

  // const handleWindowSizeChange = () => {
  //   setInnerWidth(window.innerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);
  //   window.addEventListener("scroll", handleScroll, true);
  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //     window.removeEventListener("scroll", handleScroll, true);
  //   };
  // });

  // const handleScroll = () => {
  //   if (window.pageYOffset > 10) {
  //     setTransform(true);
  //   } else {
  //     setTransform(false);
  //   }
  // };

  const renderDesktop = () => {
    return (
      <>
        <div className="hero-container" ref={props.refProp}>
          <div className="hero_content_container">
            <div>
              <video
                autoPlay
                muted
                loop
                playsInline
                // poster={posterDesktop}
                id="hero-video"
              >
                <source src={heroVideo} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
              <div className="hero-text-overlay main-margin"></div>
            </div>
          </div>
        </div>
        <div className="hero-text">
          <p className="main-margin">
            StratCap is a global alternative investment management platform
            committed to providing access to dynamic asset classes and highly
            experienced investment professionals to provide clients with
            attractive risk-adjusted returns. The company is focused on a wide
            range of digital economy investments, with an emphasis on digital
            infrastructure, sustainability, and technology-centric sectors.
          </p>
        </div>
      </>
    );
  };

  //   const renderMobile = () => {
  //     return (
  //       <div className="hero_container" ref={props.refProp}>
  //         <div className="hero_content_container">
  //           <video
  //             autoPlay
  //             muted
  //             loop
  //             playsInline
  //             poster={posterMobile}
  //             id="hero-video"
  //           >
  //             <source src={heroMobileVideo} type="video/mp4" />
  //             Your browser does not support HTML5 video.
  //           </video>
  //         </div>
  //       </div>
  //     );
  //   };
  return (
    <>
      {/* {innerWidth <= 600 ? renderMobile() : renderDesktop()} */}
      {renderDesktop()}
    </>
  );
};

export default MainPage;
